import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakt1"} />
		<Helmet>
			<title>
				Website gesucht?
			</title>
			<meta name={"description"} content={"Websites by CLEM1"} />
			<meta property={"og:title"} content={"Websites für jedermann by CLEM1"} />
			<meta property={"og:description"} content={"Keine Website, kein Problem ist mein Motto. Ich biete eine moderne Website für jedermann zu attraktiven Preisen. Einfache Websites, oder aufwendiges Design? Kein Problem!"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon.svg?v=2021-09-21T16:18:29.306Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header />
		<Section padding="36px 0 80px 0" quarkly-title="HeroBlock" md-padding="36px 0 60px 0" background="#161618">
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="60%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
					width="max-content"
					font="normal 700 60px/1.2 &quot;inter&quot;,sans-serif"
				>
					Kontakt
				</Text>
				<Text
					font="--lead"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
					order="0"
					align-self="auto"
					display="block"
					overflow-x="visible"
					min-width="max-content"
				>
					Wenn Sie Fragen haben, schicken Sie uns gerne eine E-Mail.
					<br />
					Nutzen Sie dazu einfach das Kontaktformular.
				</Text>
			</Box>
			<Box
				empty-border-style="solid"
				width="45%"
				display="flex"
				justify-content="flex-end"
				empty-border-width="1px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				align-items="center"
				md-width="100%"
				md-order="-1"
				empty-min-width="64px"
				flex="0 0 auto"
				align-self="auto"
				flex-direction="column"
			>
				<Image
					lg-max-height="400px"
					md-margin="0px 0px 30px 0px"
					lg-position="relative"
					lg-left="-5px"
					hover-opacity="1"
					hover-transition="all --transitionDuration-normal ease 20ms"
					hover-transform="translate(5px, 5px) skew(2deg, 0deg) scale3d(1.1, 1.1, 1.1)"
					transition="all --transitionDuration-normal ease 20ms"
					src="https://imagedelivery.net/nD0-EPtpqpjgeaYOA14nXg/6e9b8b9a-bb65-401f-7814-7fad9f5da400/public"
					max-width="300px"
					border-radius="20px"
				/>
			</Box>
		</Section>
		<Section background="--color-richtigesgrau">
			<Box min-width="100px" min-height="100px">
				<Box position="static" bottom="342px" top="174.75px">
					<Formspree endpoint="xrgdyyae" completeText="Die Nachricht wurde erfolgreich übermittelt." errorMessage="Irgendetwas ist schief gelaufen!" color="--proweiss">
						<Box
							gap="16px"
							display="grid"
							flex-direction="row"
							flex-wrap="wrap"
							grid-template-columns="repeat(2, 1fr)"
							grid-gap="16px"
						>
							<Box sm-width="100%" display="flex" flex-direction="column" border-radius="4px">
								<Text font="600 25px/1.3 &quot;Inter&quot;, sans-serif" margin="0 0 4px 0" color="--pr0Orange">
									Name
								</Text>
								<Input
									width="100%"
									name="name"
									type="text"
									background="--color-richtigesgrau"
									border-color="--color-proweiss"
									color="--proweiss"
									border-radius="5px"
								/>
							</Box>
							<Box sm-width="100%" display="flex" flex-direction="column">
								<Text font="600 25px/1.3 &quot;Inter&quot;, sans-serif" margin="0 0 4px 0" color="--pr0Orange">
									E-Mail
								</Text>
								<Input
									width="100%"
									type="email"
									name="email"
									border-color="--color-proweiss"
									background="--color-richtigesgrau"
									color="--proweiss"
									border-radius="5px"
								/>
							</Box>
							<Box display="flex" flex-direction="column" grid-column="1 / span 2" border-radius="4px">
								<Text font="600 25px/1.3 &quot;Inter&quot;, sans-serif" margin="0 0 4px 0" color="--pr0Orange">
									Nachricht
								</Text>
								<Input
									as="textarea"
									rows="4"
									width="100%"
									name="message"
									border-color="--color-proweiss"
									background="--color-richtigesgrau"
									color="--proweiss"
									border-radius="5px"
								/>
							</Box>
							<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
								<Button
									background="--color-pr0Orange"
									color="--proweiss"
									border-color="--color-pr0Orange"
									hover-background="--color-richtigesgrau"
									hover-border-color="--color-proweiss"
									hover-color="--proweiss"
									hover-border-width="2px"
									disabled={false}
									target="_self"
									href="/kontakt"
									hover-border-style="solid"
									border-radius="5px"
									hover-border-radius="5px"
									hover-text-align="left"
									hover-flex="0 0 auto"
									flex="0 0 auto"
									border-width="2px"
									border-style="solid"
								>
									Senden
								</Button>
							</Box>
						</Box>
					</Formspree>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<style place={"endOfBody"} rawKey={"6349caa26459a595bf58107e"}>
				{"overflow: hidden;"}
			</style>
		</RawHtml>
	</Theme>;
});